import React from 'react'
import { withRouter } from 'react-router-dom'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import theme from '../../theme'

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: '30px',
  },
}))
function SidebarNavItem(route) {
  const classes = useStyles()
  return route.navbar ? (
    <ListItem button onClick={() => route.history.push(route.path)}>
      <ListItemIcon className={classes.icon}>{route.icon}</ListItemIcon>
      {route.isDrawerOpen && <ListItemText primary={route.title} />}
    </ListItem>
  ) : (
    ''
  )
}

export default withRouter(SidebarNavItem)
