import _ from 'lodash'
import jsonmergepatch from 'json-merge-patch'

export const DataUtils = {
  isEmpty(value) {
    if (typeof value === 'object') {
      if (value === null) {
        return true
      }
      if (Array.isArray(value) && value.length === 0) {
        return true
      }
      return false
    }
    if (_.isNumber(value)) {
      return !_.isFinite(value) // if finite, return false. Covers NaN
    }
    if (value === false) {
      return value
    }

    return _.isEmpty(value)
  },
  getFormattedBody(object) {
    const formBodyKeys = Object.keys(object || {})
    const formattedBody = formBodyKeys.reduce((body, formKey) => {
      const formKeyValue = object[formKey]
      const isEmpty =
        (Array.isArray(formKeyValue) && formKeyValue.length === 0) || formKeyValue === null || formKeyValue === ''
      if (!isEmpty) {
        body = {
          ...body,
          [formKey]: formKeyValue,
        }
      }
      return body
    }, {})
    return formattedBody
  },
  getPatchFormattedBody(object) {
    const formBodyKeys = Object.keys(object || {})
    const formattedBody = formBodyKeys.reduce((body, formKey) => {
      const formKeyValue = object[formKey]

      if (formKeyValue === '') {
        body = {
          ...body,
          [formKey]: null,
        }
      } else {
        body = {
          ...body,
          [formKey]: formKeyValue,
        }
      }
      return body
    }, {})

    return formattedBody
  },
  get(obj1, obj2) {
    const result = {}
    if (Object.is(obj1, obj2)) {
      return undefined
    }
    if (!obj2 || typeof obj2 !== 'object') {
      return obj2
    }
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
          result[key] = obj2[key] === '' ? null : obj2[key]
        }
        if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
          const value = this.getDiff(obj1[key], obj2[key])
          if (value !== undefined) {
            result[key] = value
          }
        }
      })
    return result
  },

  getDiff(obj1, obj2) {
    const val = jsonmergepatch.generate(obj1, obj2)
    return val
  },
  getNested(data, path, defaultValue) {
    const extractedData = _.get(data, path, defaultValue)
    return extractedData === null ? defaultValue : extractedData
  },
  convertArrayToCamelCase(data) {
    return data.map((datum) => _.camelCase(datum))
  },
  getFormattedForm(form) {
    const formKeys = Object.keys(form)
    const formattedForm = formKeys.reduce((formattedObject, current) => {
      if (form[current] === '') {
        return {
          ...formattedObject,
          [current]: null,
        }
      } else {
        return {
          ...formattedObject,
          [current]: form[current],
        }
      }
    }, {})
    return formattedForm
  },
  isArray(input) {
    return Array.isArray(input)
  },

  isObject(obj) {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function'
  },
  camelize(input) {
    return (function recurse(input) {
      if (DataUtils.isObject(input)) {
        return Object.keys(input).reduce((acc, key) => {
          return Object.assign(acc, {
            [DataUtils.toCamel(key)]: recurse(input[key]),
          })
        }, {})
      } else if (DataUtils.isArray(input)) {
        return input.map((i) => recurse(i))
      }
      return input
    })(input)
  },

  snakize(input) {
    return (function recurse(input) {
      if (DataUtils.isObject(input)) {
        return Object.keys(input).reduce((acc, key) => {
          return Object.assign(acc, {
            [DataUtils.toSnake(key)]: recurse(input[key]),
          })
        }, {})
      } else if (DataUtils.isArray(input)) {
        return input.map((i) => recurse(i))
      }
      return input
    })(input)
  },
  toCamel(str) {
    return str.replace(/([_-][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  },
  toSnake(str) {
    return _.snakeCase(str)
  },
  sortKeyToSnake(str) {
    const splitStrings = str.split('.')
    const snakizedSplitStrings = splitStrings.map((datum) => _.snakeCase(datum))
    return snakizedSplitStrings.join('.')
  },
}
