import React from 'react'
import loadable from '@loadable/component'
import { BarChart, DocumentScanner, People, CreditScore, RoomServiceOutlined } from '@mui/icons-material'
import DomainIcon from '@mui/icons-material/Domain'
import DomainAddIcon from '@mui/icons-material/DomainAdd'

const getLoader = () => <div>Loading....</div>

export const publicRoutes = [
  {
    path: '/login',
    component: loadable(() => import('../pages/Login/Login'), {
      fallback: getLoader(),
    }),
    name: 'login',
  },
]

export const privateRoutes = [
  {
    path: '/home',
    component: loadable(() => import('../pages/User/Users'), {
      fallback: getLoader(),
    }),
    icon: <BarChart />,
    name: 'dashboard',
    title: 'Dashboard',
    navbar: true,
  },
  {
    path: '/properties/new',
    component: loadable(() => import('../pages/Property/NewProperty'), {
      fallback: getLoader(),
    }),
    icon: <DomainAddIcon />,
    name: 'new-property',
    title: 'New Property',
    navbar: false,
  },
  {
    path: '/properties/:propertyId',
    component: loadable(() => import('../pages/Property/PropertyDetail'), {
      fallback: getLoader(),
    }),
    name: 'propertyDetail',
    title: 'Property Detail',
    navbar: false,
  },
  {
    path: '/properties',
    component: loadable(() => import('../pages/Property/Properties'), {
      fallback: getLoader(),
    }),
    icon: <DomainIcon />,
    name: 'properties',
    title: 'Properties',
    navbar: true,
  },
  {
    path: '/admins',
    component: loadable(() => import('../pages/User/Users'), {
      fallback: getLoader(),
    }),
    icon: <People />,
    name: 'user',
    title: 'Admins',
    navbar: true,
  },
  {
    path: '/residents',
    component: loadable(() => import('../pages/User/Residents'), {
      fallback: getLoader(),
    }),
    icon: <People />,
    name: 'resident',
    title: 'Residents',
    navbar: true,
  },
  {
    path: '/property-checklists',
    component: loadable(() => import('../pages/Checklist/Checklist'), {
      fallback: getLoader(),
    }),
    icon: <DocumentScanner />,
    name: 'checklist',
    title: 'Document Sharing',
    navbar: true,
  },
  {
    path: '/payment-setup',
    component: loadable(() => import('../pages/Payment/Payments'), {
      fallback: getLoader(),
    }),
    icon: <CreditScore />,
    name: 'payment',
    title: 'Stripe Connect',
    navbar: true,
  },
  {
    path: '/payment-setup/success',
    component: loadable(() => import('../pages/Payment/Payments'), {
      fallback: getLoader(),
    }),
    name: 'payment-setup-success',
    title: 'Stripe Connect',
    navbar: false,
  },
  {
    path: '/payment-setup/refresh',
    component: loadable(() => import('../pages/Payment/Payments'), {
      fallback: getLoader(),
    }),
    name: 'payment-setup-refresh',
    title: 'Stripe Connect',
    navbar: false,
  },
  {
    path: '/services',
    component: loadable(() => import('../pages/Service/Services'), {
      fallback: getLoader(),
    }),
    icon: <RoomServiceOutlined />,
    name: 'services',
    title: 'Services',
    navbar: true,
  },
  {
    path: '/',
    component: loadable(() => import('../pages/User/Users'), {
      fallback: getLoader(),
    }),
    icon: <BarChart />,
    name: 'home',
    title: 'Dashboard',
  },
]
