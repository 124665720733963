import _ from 'lodash'

export const StringUtils = {
  isEmpty(value) {
    if (_.isNumber(value)) {
      return !_.isFinite(value) // if finite, return false. Covers NaN
    }

    return _.isEmpty(value)
  },
  trimFirstAndLastCharacter(string) {
    return string.substring(1, string.length - 1)
  },
  isEmail(email) {
    const re = /\S+@\S+\.\S+/
    return email && re.test(email)
  },
}
