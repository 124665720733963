import SecureLS from 'secure-ls'

const env = process.env.NODE_ENV
const encodingType = env.toString() === 'development' ? '' : 'des'
const isCompression = env.toString() !== 'development'
const encryptionSecret = process.env.REACT_APP_SECRET_KEY_LOCALSTORAGE
const ls = new SecureLS({
  encodingType: encodingType,
  isCompression: isCompression,
  encryptionSecret: encryptionSecret,
})
export const LocalStorageUtils = {
  localStorageDecoder: (key) => {
    try {
      // if( key === 'token')return "token" // for testing purpose
      return ls.get(key)
    } catch (e) {
      return false
    }
  },
  localStorageEncoder: (key, encodeValue) => {
    try {
      ls.set(key, encodeValue)
    } catch (e) {
      console.log('localstorage setting error', e)
    }
  },
  localStorageRemover: () => {
    try {
      ls.removeAll()
      ls.remove('_secure__ls__metadata')
    } catch (e) {
      console.log('localstorage removing error', e)
    }
  },
  particularValueRemover: (key) => {
    try {
      ls.remove(key)
      ls.remove('_secure__ls__metadata')
    } catch (e) {
      console.log('localstorage removing error', e)
    }
  },
}
export default LocalStorageUtils
