import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LocalStorageUtils } from '../utils'
import Layout from '../components/Layout'
const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticate = () => LocalStorageUtils.localStorageDecoder('token')

  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthenticate() ? (
          <Layout MainComponent={<Component {...props} />} {...props} {...rest} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}
export default PrivateRoute
