import axios from 'axios'
import LocalStorageUtils from './LocalStorageUtils'

axios.defaults.baseURL = process.env.REACT_APP_ADMIN_API_URL
axios.defaults.headers.common.Authorization = LocalStorageUtils.localStorageDecoder('token')

axios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = LocalStorageUtils.localStorageDecoder('token')
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    // intercept the global error
    return response
  },
  async function (error) {
    if (!error.response) {
      return Promise.reject(error)
    }

    try {
      const { device: deviceId } = error.response.headers
      if (typeof deviceId === 'string' && deviceId.length) {
        LocalStorageUtils.localStorageEncoder('deviceId', deviceId)
      }
    } catch (e) {
      console.log(e.toString())
    }

    const originalRequest = error.config

    if (error.response.status !== 200 && originalRequest.url === '/auth/refresh-token') {
      LocalStorageUtils.particularValueRemover('token')
      return Promise.reject(error)
    }

    // if the error is 401 and hasn't already been retried
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = LocalStorageUtils.localStorageDecoder('refresh_token')
      LocalStorageUtils.particularValueRemover('token')

      if (!(typeof refreshToken === 'string' && refreshToken.length)) {
        window.location = '/login'
        return Promise.reject(error)
      }
      return new Promise((resolve, reject) => {
        axios
          .post(
            '/auth/refresh-token',
            {},
            {
              headers: {
                refreshToken: refreshToken,
              },
            }
          )
          .then(async ({ data: { data } }) => {
            LocalStorageUtils.localStorageEncoder('token', data.token)
            LocalStorageUtils.localStorageEncoder('refresh_token', data.refreshToken)
            axios.defaults.headers.common.Authorization = data.token
            resolve(axios(originalRequest))
          })
          .catch(async (error) => {
            window.location = '/login'
            reject(error)
          })
      })
    } else {
      return Promise.reject(error)
    }
  }
)

export default axios
